import http from "./httpService";
import jwtDecode from "jwt-decode";
import md5 from 'md5';

const apiEndpoint = "";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(account, password) {
  console.log('md5', md5(password))

  try {
    // const data = { account: account, password: md5(password), role: 'plan'};
    const { data: result } = await http.get(apiEndpoint + `/authen/?account=${account}&password=${md5(password)}`);
    
    if ( result.status === 'success') {

      console.log(result)

      const jwt = result.data.user.token;
      if(jwt) {
        localStorage.setItem(tokenKey, jwt);

        console.log("login", "success")
    
        return "OK";
      } else {
        return "Token Not Found"
      }
      
    } else {
      return result.message;
    }
  } catch(err) {
    console.log(err);
    return err.message;
  }
  
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
};
