import React, {Component} from "react";
import { Link, NavLink } from "react-router-dom";
import ConfirmLogout from './../common/confirmLogout';
import Clock from 'react-live-clock';

class NavBar extends Component {
  state = { 
    collapsed: true,
    isModalLogoutOpen: false,
   }

  toggleNavbar =() => this.setState({ collapsed: !this.state.collapsed });
  hide = () => this.setState({ collapsed: true});

  doLogout = async () => {
    this.setState({isModalLogoutOpen: true, collapsed: true})
  }

  onLogoutCancel = () => {
    this.setState({isModalLogoutOpen: false})
  }

  onLogoutConfirm = async () => {
    this.setState({isModalDeleteOpen: false})

    this.props.history.replace("/logout");
  }

  render() { 
    const { user} = this.props;

    const { collapsed, isModalLogoutOpen}  = this.state;
    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    // const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return ( 
      <React.Fragment>
        <ConfirmLogout isOpen={isModalLogoutOpen} onConfirm={this.onLogoutConfirm} onCancel={this.onLogoutCancel} />
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/">
            Real Estate Management
          </Link>
          <h5 className="d-none d-xl-inline"><span className="badge badge-light mt-2"><Clock className="align-baseline" format="ddd" ticking={true} interval={60000} /></span></h5>
          <h5 className="d-none d-md-inline d-lg-none d-xl-inline"><span className="badge badge-light ml-2 mt-2"><Clock className="align-baseline" format="D MMMM YYYY" ticking={true} interval={60000} /></span></h5>
          <h5 className="d-none d-sm-inline"><span className="badge badge-light ml-2 mt-2"><Clock className="align-baseline" format="HH:mm:ss" ticking={true} interval={1000} /></span></h5>
          
          <button
            className="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.toggleNavbar}
          
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={classOne} id="navbarNavAltMarkup">
            <div className="navbar-nav ml-auto">
              {!user && (
                <React.Fragment>
                  <NavLink className="nav-item nav-link" to="/login" onClick={this.hide}>Login</NavLink>
                </React.Fragment>
              )}
              {user && (
                <React.Fragment>
                  <NavLink className="nav-item nav-link" to="/meterwork" onClick={this.hide}>บันทึกมิเตอร์</NavLink>
                  {/* <NavLink className="nav-item nav-link" to="/report" onClick={this.hide}>Report</NavLink> */}

                  {/* <NavLink className="nav-item nav-link" to="/branch" onClick={this.hide}>พื้นที่</NavLink> */}
                  {/* <NavLink className="nav-item nav-link" to="/line" onClick={this.hide}>LINE</NavLink> */}
                  {/* <a className="nav-item nav-link" target="_blank" rel="noopener noreferrer" href="https://aanotify.netcomdns.com/public/aA_Notify_Manual_20190917.pdf" >Help</a> */}
                  {/* <NavLink className="nav-item nav-link" to="/staff" onClick={this.hide}>Staff</NavLink> */}
                  {/* <NavLink className="nav-item nav-link" to="/profile" onClick={this.hide}>Profile</NavLink> */}
                  <NavLink className="nav-item nav-link" to="/logout" onClick={this.hide}>Logout</NavLink>
                </React.Fragment>
              )}
            </div>
          </div>
        </nav>
      </React.Fragment>
      
     );
  }
}

export default NavBar;
