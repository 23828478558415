import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logService";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers['locale'] = 'TH';

// declare a request interceptor
axios.interceptors.request.use(config => {
  // perform a task before the request is sent
  console.log('Request was sent', config);

  return config;
}, error => {
  // handle the error
  return Promise.reject(error);
});

axios.interceptors.response.use(null, error => {
  if ( error.response && error.response.data && error.response.data.message) {
    toast.error("ผิดพลาด : " + error.response.data.message);

  } else {
    const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

    
    if (!expectedError) {
      try {
        logger.log(error);
      } catch (ex) {
        console.log(ex);
      }

      console.log("INTERCEPTOR Logginge the error", error);
      toast.error("An unexpected error");
    }

  }

  return Promise.reject(error);

  
});

function setJwt(jwt) {
  axios.defaults.headers["x-auth-token"] = jwt;
  axios.defaults.headers["token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
